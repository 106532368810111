import React from 'react';
import './Header.css';
import AdminLogin from './AdminLogin'


function Header() {
  return (
    <header className="header">
      <div className="header-logo">
        <h1>माँ आद्यशक्ति </h1>
      </div>
      <nav className="header-nav">
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#donate">Donate</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="#Login">AdminLogin</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
