import React from 'react';
import './Acharya.css'; // Ensure the CSS is imported correctly
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'; // Importing social icons

const Acharya = () => {
  return (
    <div className="acharya-container">
      <div className="acharya-header">
        <img
          className="acharya-img"
          src="/assets/images/Acharya.jpg" // Ensure the image path is correct
          alt="Acharya Shri Chakravarti Jha"
        />
       <h1>Acharya Shri Chakravarti Jha</h1>
<p>
  <strong>Pandit | Vaidik-Tantrik | Jyotish | Special-Case </strong>
</p>
</div>

      <div className="profile-section">
        <h2>Introduction</h2>
        <p>
          "नमस्ते! मैं भुवानी पुत्र चक्रवर्ती झा हूँ, एक समर्पित आचार्य, जो पूजा के पवित्र क्षेत्र में 16 वर्षों का अनुभव समेटे हुए हैं। तांत्रिक और विशेष पूजा में 10 वर्षों की मेरी विशेषज्ञता, मुझे लोगों के जीवन में सकारात्मक बदलाव लाने में सहायता करती है। माँ आदिशक्ति की कृपा से, मैं सदैव समाज की सेवा करने के लिए तत्पर हूँ, क्योंकि मेरा मानना है कि सच्ची भक्ति और सेवा ही मानवता के उत्थान का मार्ग है। मेरा उद्देश्य है लोगों को आशीर्वाद देना और उनके जीवन में सुख, शांति और समृद्धि लाना। हर पूजा, हर अनुष्ठान में मैं उनके मन की गहराइयों तक पहुँचने का प्रयास करता हूँ, ताकि उनकी इच्छाएँ और सपने सच हो सकें।"
        </p>
      </div>

      <div className="profile-section">
        <h2>Educational Details</h2>
        <ul>
          <li>Bachelor of Commerce - LNMU Darbhanga</li>
          <li>Bachelor/Shashtri of Sanskrit (Ved-Karmakand) - Kameshwar Singh Vishwavidyalaya Darbhanga</li>
          <li>Master/Acharya in Sanskrit (Ved-Karmakand) - Kameshwar Singh Vishwavidyalaya Darbhanga</li>
        </ul>
      </div>

      <div className="profile-section">
        <h2>Professional Details</h2>
        <ul>
    <li>
      <strong>हिंदू पूजा-पाठ (2008 से वर्तमान):</strong> 
      16 वर्षों से अधिक का अनुभव, विभिन्न धार्मिक अनुष्ठानों और पूजा-पाठ में विशेषज्ञता, जो भक्तों को सही दिशा में मार्गदर्शन करता है।
    </li>
    <li>
      <strong>वास्तु शास्त्र (2010 से वर्तमान):</strong> 
      घर और संपत्ति के वास्तु को सही करने के लिए उचित सलाह और उपाय प्रदान करना, जिससे परिवार में सुख और समृद्धि बनी रहे।
    </li>
    <li>
      <strong>वेद-कर्मकांड (2014 से वर्तमान):</strong> 
      वेदों के अनुसार विशेष पूजा और अनुष्ठान करने में कुशलता, जो आध्यात्मिक ऊर्जा को बढ़ाता है।
    </li>
    <li>
      <strong>तांत्रिक (2016 से वर्तमान):</strong> 
      तांत्रिक साधना और अनुष्ठानों के माध्यम से सकारात्मक ऊर्जा का संचार करना, जिससे जीवन में सामर्थ्य और संतुलन प्राप्त किया जा सके।
    </li>
    <li>
      <strong>शिव-शक्ति पूजन (नवरात्रि, रुद्राभिषेक, महामृत्युञ्जय, लाखवारी, आदि) (2015 से वर्तमान):</strong> 
      विशेष अवसरों पर शिव और शक्ति की पूजा का आयोजन, जो भक्तों को आध्यात्मिक बल और प्रेरणा प्रदान करता है।
    </li>
    <li>
      <strong>विशेष पूजा (बागलामुखी साधना, प्रत्यंगिरा साधना, आदि) (2018 से वर्तमान):</strong> 
      विशेष पूजा अनुष्ठानों के द्वारा व्यक्तियों की समस्याओं का समाधान, जो व्यक्तिगत विकास और समृद्धि में सहायक होता है।
    </li>
    </ul>
      </div>

      <div className="profile-section">
        <h2>Special Activities</h2>
        <ul>
        <li>
      <strong>शांति पूजन (Shanti Poojan):</strong> 
      यह पूजा मानसिक और आध्यात्मिक शांति को बढ़ावा देती है, जिससे सभी प्रकार के नकारात्मक ऊर्जा को दूर किया जा सके।
    </li>
    <li>
      <strong>गृह प्रवेश (Grih Pravesh):</strong> 
      नए घर में प्रवेश करने से पहले की जाने वाली यह पूजा, आपके घर को सकारात्मक ऊर्जा से भर देती है और सुख-समृद्धि की कामना करती है।
    </li>
    <li>
      <strong>ग्रह-शांति अनुष्ठान (Grah-Shanti Anushthan):</strong> 
      यह अनुष्ठान ग्रहों की स्थिति को संतुलित करने के लिए किया जाता है, ताकि जीवन में आने वाली बाधाएँ दूर हों।
    </li>
    <li>
      <strong>तांत्रिक बंध मुक्ति साधना (Tantra-Badha Mukti Sadhana):</strong> 
      यह साधना व्यक्ति के जीवन में किसी भी प्रकार की तांत्रिक बाधाओं से मुक्ति दिलाने में मदद करती है।
    </li>
    <li>
      <strong>स्वास्थ्य, व्यापार-वृद्धि, न्यायिक-समाधान (Swasthya, Vyapar-Vriddhi, Nyayaik-Samadhan):</strong> 
      यह पूजा स्वास्थ्य, व्यापार में वृद्धि और न्यायिक मामलों के समाधान के लिए विशेष रूप से की जाती है।
    </li>
    <li>
      <strong>पितृ-दोष, पुत्र-कामना, शादी-विवाह (Pitra-Dosh, Putra-Kamana, Shadi-Vivah):</strong> 
      ये अनुष्ठान पितृ दोषों का निवारण करते हैं, संतान प्राप्ति की कामना और विवाह के लिए विशेष रूप से किए जाते हैं।
    </li>
        </ul>
      </div>

      {/* Footer Section */}
      <footer className="acharya-footer">
        <div className="contact-details">
          <h2>Contact Details</h2>
          <p>Email: <a href="mailto:chakravartijha7@gmail.com">chakravartijha7@gmail.com</a></p>
          <p>Phone: <a href="tel:+919835578439">+91-9835578439</a></p>
        </div>
        <div className="social-icons">
          <h2>Follow Me</h2>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook size={30} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={30} />
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <FaYoutube size={30} />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Acharya;
