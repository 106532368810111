import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Events from './components/Events';
import Donations from './components/Donations';
import Expenditure from './components/Expenditure';
import Contact from './components/Contact';
import AdminLogin from './components/AdminLogin';
import Acharya from './components/Acharya';

function App() {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    // Call the backend API to get members
    axios.get('https://aadishakti-backend.onrender.com/api/member')
      .then(response => {
        setMembers(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the members!", error);
      });
  }, []);

  return (
    <div>
      <h1>Member List</h1>
      <ul>
        {members.map(member => (
          <li key={member._id}>{member.name}</li>
        ))}
      </ul>

      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/donations" element={<Donations />} />
          <Route path="/expenditure" element={<Expenditure />} />
          <Route path="/Admin-Login" element={<AdminLogin />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Acharya" element={<Acharya />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
