import React, { useState } from 'react';
import './Home.css';
import Header from './Header';
import { Link } from 'react-router-dom';  // Importing Link from react-router-dom

function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  const images = [
    '/assets/images/image1.jpg',
    '/assets/images/image2.jpg',
    '/assets/images/image3.jpg',
  ];

  const topDonations = [
    { name: 'Bajrangi', amount: '₹10,000' },
    { name: 'Kanak Kumar', amount: '₹9,500' },
    { name: 'Rahul Kumar', amount: '₹8,700' },
  ];

  const priests = [
    { name: 'Acharya Shri Chakravarti Jha', image: '/assets/images/Acharya.jpg' },
    { name: 'Shri Kameshwar Singh', image: '/assets/images/priest2.jpg' },
    { name: 'Priest 3', image: '/assets/images/priest3.jpg' },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const previousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="home-container">
      <Header />
      {/* Background Music */}
      <audio autoPlay loop muted={isMuted}>
        <source src="/assets/music/bhajan.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <button className="mute-button" onClick={toggleMute}>
        {isMuted ? 'Unmute Bhajan' : 'Mute Bhajan'}
      </button>

      {/* Home Banner */}
      <div className="home-banner">
        <h1>माँ आद्यशक्ति</h1>
        <p>
          A spiritual sanctuary where the power of the Divine Mother illuminates
          our lives. Experience the tranquility and devotion that transcends the
          material world.
        </p>
        <button className="donate-button">Donate Now</button>
      </div>

      {/* Priest Section */}
      <div className="priests-section">
        <h2>Our Priests</h2>
        <div className="priests-gallery">
          {priests.map((priest, index) => (
            <div key={index} className="priest-card">
              <Link to={priest.name === "Acharya Shri Chakravarti Jha" ? "/acharya" : "#"}>
                <img src={priest.image} alt={priest.name} className="priest-photo" />
                <p>{priest.name}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Gallery Slideshow */}
      <div className="gallery-section">
        <h2>Gallery</h2>
        <div className="gallery-slideshow">
          <button className="prev-button" onClick={previousSlide}>
            &#10094;
          </button>
          <img src={images[currentSlide]} alt="Slideshow" className="gallery-image" />
          <button className="next-button" onClick={nextSlide}>
            &#10095;
          </button>
        </div>

        {/* Top Donations */}
        <div className="donations-section">
          <h3>Top 10 Donations</h3>
          <ul>
            {topDonations.map((donation, index) => (
              <li key={index}>
                {donation.name}: {donation.amount}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Chairman and Steward Section */}
      <div className="committee-section">
        <h2>Temple Committee</h2>
        <div className="committee-members">
          <div className="member-card">
            <img src="/assets/images/chairman.jpg" alt="Chairman" className="committee-photo" />
            <p>Shri Rajesh Kumar Rai
              <p>Chairman (Sarpanch)</p>
            </p>
          </div>
          <div className="member-card">
            <img src="/assets/images/steward.jpg" alt="Steward" className="committee-photo" />
            <p>Shri Kanak Kumar
              <p>CA (Steward)</p>
            </p>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="services-section">
        <h2>Temple Services</h2>
        <ul>
          <li>Daily Aarti & Pooja</li>
          <li>Special Rituals & Havan</li>
          <li>Prasad Distribution</li>
          <li>Charity & Social Service Initiatives</li>
        </ul>
      </div>

      <div className="footer">
        <p>© नवयुवक दुर्गा पूजा समिति बलहा | All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Home;
