import React from 'react';

function Contact() {
  return (
    <div>
      <h2>Contact Us</h2>
      <p>Address: माँ आद्यशक्ति, समस्तीपुर, बिहार </p>
      <p>Phone: +91 9523829492</p>
      <p>Email: balha.aadishakti@gmail.com</p>
    </div>
  );
}

export default Contact;
