import React from 'react';

function Events() {
  return (
    <div>
      <h2>Upcoming Events</h2>
      <ul>
        <li>Navratri Festival - Oct 10-18</li>
        <li>Diwali Celebrations - Nov 5</li>
        <li>Makar Sankranti Pooja - Jan 14</li>
      </ul>
    </div>
  );
}

export default Events;
