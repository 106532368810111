import React, { useState } from 'react';

function Donations() {
  const [amount, setAmount] = useState('');

  const handleDonate = (e) => {
    e.preventDefault();
    // Implement payment gateway API call here
    alert(`Thank you for donating ₹${amount}!`);
  };

  return (
    <div>
      <h2>Donate to मां आदिशक्ति</h2>
      <form onSubmit={handleDonate}>
        <label>
          Enter Amount (₹):
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </label>
        <button type="submit">Donate</button>
      </form>
    </div>
  );
}

export default Donations;
