import React from 'react';

function Expenditure() {
  return (
    <div>
      <h2>Temple Expenditure</h2>
      <ul>
        <li>Construction Expenses: ₹2,00,000</li>
        <li>Event Arrangements: ₹50,000</li>
        <li>Maintenance: ₹30,000</li>
      </ul>
    </div>
  );
}

export default Expenditure;
